html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  /* font-family: sans-serif; */
  color: grey;
  font-size: 16px;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
  background-color: rgb(242, 242, 242);
}

header {
  background-color: white;
}
.header-logo {
  max-width: 350px;
  padding: 2em 0 4em;
}
.header-link {
  font-weight: 100;
  position: absolute;
  bottom: 0%;
  color: grey;
}
.header-email {
  font-weight: 100;
  position: absolute;
  bottom: 0%;
  color: grey;
  left: 50%;
  transform: translateX(-50%);
}
.fb-link {
  left: 7px;
}
.slides-link {
  right: 7px;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  color: rgb(77, 77, 77);
}
header a {
  color: #3b5998;
}

footer {
  margin: 2em;
}
footer.footer {
  text-align: center;
}
footer p {
  margin-bottom: .25em;
}

/* img {
  display: block;
  vertical-align: bottom;
} */

/* .home-gallery-preview {
  margin: 0 2em;
} */

.home-gallery-preview {
  margin: 2em;
  text-align: left;
}

.home-gallery-preview h2 {
  margin-bottom: 0;
}
.home-gallery-date {
  margin: 0;
}
.home-gallery-length {
  margin-top: 0;
  font-size: .75em;
}

.enter-icon {
  height: 1em;
  vertical-align: bottom;
  margin-left: .25em;
}

.home-gallery-image-wrapper {
  padding: 1em;
  background-color: white;
}

.home-gallery-images-wrapper {
  /* position: relative; */
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.gallery-images-wrapper {
  justify-content: space-evenly;
}

.home-gallery-image-wrapper {
  /* flex: 1 1 1; */
  max-width: 33%;
}

.gallery-image-wrapper {
  margin-top: 1em;
}

.home-gallery-image {
  /* border: 1em solid white; */
  margin: 0 2em;
  max-width: 500px;
  max-height: 500px;
  /* width: 100%; */
  max-width: 100%;
  margin: 0;
}

/**
  Mobile tweaks
**/
@media only screen and (max-width: 600px) {
  .header-logo {
    max-width: 150px;
    padding: 2em 0;
  }
  .header-email {
    margin-top: 0;
    position: relative;
  }
  .header-link {
    position: relative;
    margin: .5em;
  }
  .fb-link {
    left: 0;
  }
  .slides-link {
    right: 0;
    padding-bottom: 1em;
  }
  .home-gallery-preview {
    margin: 2em 1em;
  }

  .home-gallery-preview h2 {
    font-size: 1em;
  }
  .home-gallery-date {
    font-size: .7em;
  }
  .home-gallery-length {
    font-size: .55em;
  }

  .home-gallery-image-wrapper {
    padding: .75em;
    min-height: 95px;
  }
}

@media only screen and (max-width: 9024px) {

}